import React from "react";
import { TypeHomepageV2Logo } from "../../utils/queries/homepagev2";

const HomepageLogoList = ({
  logos,
  grayscale = false,
}: {
  logos: Array<TypeHomepageV2Logo>;
  grayscale?: boolean;
}) => {
  

  const renderedLogos = logos.map((logo, index) => (
    <div key={index} className="logo">
      <img
        loading="lazy"
        src={logo.logo.url}
        style={{
          filter: grayscale ? "grayscale(1)" : "none",
        }}
      />
    </div>
  ));

  return (
    <div className={`grid ${logos.length >= 7 ? 'lg:grid-cols-7' : 'lg:grid-cols-6'} md:grid-cols-4 grid-cols-2 items-center lg:gap-x-10 gap-y-5 gap-x-5 justify-between px-3 logo-list`}>
      {renderedLogos}
    </div>
  );
};

export default HomepageLogoList